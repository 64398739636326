<template>
    <div class="login-container">
        <el-form ref="loginForm" :model="formData" :rules="rules" class="login-form" label-width="80px">
            <h1>登录</h1>
            <el-form-item prop="username" label="用户名">
                <el-input v-model="formData.username" placeholder="请输入用户名" style="height: 50px"></el-input>
            </el-form-item>
            <el-form-item prop="password" label="密码">
                <el-input type="password" v-model="formData.password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item prop="captcha" label="验证码">
                <div style="display: flex">
                    <el-input v-model="formData.captcha" placeholder="请输入验证码"></el-input>
                    <span class="captcha" v-html="captchaSVG" @click="captcha"></span>
                </div>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="login" style="height: 50px; width: 100%">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {getCaptcha,userLogin} from "../../api/login"

export default {
    data() {
        return {
            formData: {
                username: '',
                password: '',
                captcha:"",
                captchaKey: ''
            },
            getCaptchaStatus:false,
            captchaSVG: "",
            rules: {
                username: [
                    {required: true, message: '请输入用户名', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'}
                ],
                captcha: [
                    {required: true, message: '请输入验证码', trigger: 'blur'}
                ]
            }
        }
    },
    mounted() {
        this.captcha()
    },
    methods: {
        login() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    userLogin(this.formData).then(r=>{
                        if(r.code === 0){
                            this.$router.push('/home')
                            localStorage.setItem('userInfo', JSON.stringify(r.data))
                            localStorage.setItem('token', r.token)
                        }
                    }).catch(()=>{
                        this.captcha()
                    })
                }
            })
        },
        async captcha() {
            if(this.getCaptchaStatus) return
            try {
                const r = await getCaptcha()
                this.formData.captchaKey = r.data.captchaKey
                this.captchaSVG = r.data.svg
                this.getCaptchaStatus = false
            } catch (e) {
                this.$message.error("获取验证码图片失败，请检查网络")
                this.getCaptchaStatus = false
            }
        }
    }
}
</script>

<style scoped>
.login-container {
    background-color: #F5F7FA;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    width: 400px;
    background-color: #FFFFFF;
    padding: 50px 70px 50px 50px;
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}

h1 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}

.captcha {
    margin-left: 16px;
    display: block;
    width: 150px;
    height: 50px;
    cursor: pointer;
}
</style>
<style lang="scss">
.login-container .el-input__inner{
  height: 50px;
  line-height: 50px;
}
.login-container .el-form-item__label{
  line-height: 50px;
}
</style>
