import service from "@/utils/ajax"

export const getCaptcha = (params)=>{
    return service({
        url:"/v1/captcha/getCaptcha",
        method:"GET",
        params
    })
}

export const userLogin = (data)=>{
    return service({
        url:"/v1/adminUser/login",
        method:"post",
        data
    })
}
